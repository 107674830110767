import * as service from './service'
import notification from '../../../components/notification';
import {capitalize, generateLabels} from "../../../helpers";

export default {
  state: {
    devices: [],
    circlesConnected: {},
    services:{}
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onError(state,data) {
      let err = data && data.message ? data.message : data && data.data && data.data.message ? data.data.message :  'Please try again later'  ;
      notification("error", capitalize(err));
      return {
        ...state,
        loading: false
      };
    },
    onListDevicesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        devices: data
      }
    },
    onListCirclesConnSuccess(state, data) {
      return {
        ...state,
        loading: false,
        circlesConnected: data
      }
    },
    onListServicesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        services: data
      }
    },
    onDeleteDeviceSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_DEVICE_DELETE"))
      return {
        ...state,
        loading: false,
      }
    }
  },
  effects: {
    // async listDevices(payload, rootState) {
    //   this.onRequest()
    //   try {
    //     let res = await service.listDevices(payload)
    //     this.onListDevicesSuccess(res)
    //     return res
    //   }
    //   catch(e) {
    //     this.onError(e)
    //   }
    // },
    async deleteDevice(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.deleteDevice(payload)
        this.onDeleteDeviceSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async listServices(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.listServices(payload)
        this.onListServicesSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async listDevices(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.listDevices(payload)
        this.onListDevicesSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async listCirclesConnected(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.listCirclesConnected(payload)
        this.onListCirclesConnSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
  }
}