import queryString from "qs";
import {reactLocalStorage} from 'reactjs-localstorage';
// import moment  from "moment";
import localization from 'moment/locale/cy'
import config from '../../config'
import { en } from '../../lang/en'
import { wel } from '../../lang/welsh'
import { setAllCookie, getCookieByValue } from '../helpers/utility'
import moment from "moment-timezone"


import lodash from 'lodash'

export const groupBy = (xs, key) => {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function aclCheck(event) {
  let ACL = reactLocalStorage.getObject('acl');
  if(ACL && ACL.length){
    let  index = ACL.findIndex(x => x.value === event);
    if(index !== -1)
      return true
    else 
      return false  
  }else{
    return false
  }
}

export function sortFunction(data) {
    let sort = reactLocalStorage.getObject('sortList');
    if(sort && Object.keys(sort).length){
    if(sort[data]) {
      let sort_by = sort[data].substring(0, sort[data].lastIndexOf('_'));
      let sort_order = sort[data].substr(sort[data].lastIndexOf('_') + 1);
      return {sort_by, sort_order}
    }
  }
}

export function updateSortFunction(data, sort_by, sort_order) {
  let sort = reactLocalStorage.getObject('sortList');
  if(sort && Object.keys(sort).length){
    sort[data] = `${sort_by}_${sort_order}`
    reactLocalStorage.setObject('sortList', sort);
  }
}

export const objToUrlParams = obj => {
  return queryString.stringify(cleanObject(obj));
};
export const generateRandomKey = obj => {
  return  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const capitalize = str => {  
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export const getDate = date => {  
  return moment(date).locale(`${getCookieByValue("lang") === "wel" ? "cy" : "en"}`, localization).format(config.dateFormat);
};
export const getZoneDate = date=> {
  return moment(date).tz('Europe/London').locale(`${getCookieByValue("lang") === "wel" ? "cy" : "en"}`, localization).format(config.dateFormat);
}

export function cleanObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}
export function generateURL(modules,fileName,type) {
  let URL = reactLocalStorage.getObject('cloudfront');

  if(URL && URL.cloudfrontUrl && URL.cloudfrontUrl[modules] && URL.cloudfrontUrl[modules][type]){
    let cloudfrontUrl = URL.cloudfrontUrl[modules][type]
    return `${cloudfrontUrl}${fileName}`;
  }else{
    return ''
  }
}

export function orderCode(sorter) {
  let order = {};
  if (sorter && sorter.field)
    order = {
      [sorter.field]: [sorter.order]
    };
  return order;
}

export function orderMap(order) {
  const orderKeys = { descend: "DESC", ascend: "ASC" };
  return Object.keys(order).map(key => [key, orderKeys[order[key]]]);
}


export async function groupByDates(payload) {
  try {    
    let filter = await filterByDates(payload)
    // console.log("aaaaaaa--------------", filter)

    let eventList = lodash.chain(filter)
    .groupBy('startDate')
    .toPairs()
    .map(function (currentItem) { return lodash.zipObject(['date', 'data'], currentItem) }).value();

    // let sortedResult 	= lodash.sortBy(eventList, function(item) {
    //   return new Date( item.date );
    // });
    let data = {
      rows: eventList,
      count: payload.count,
      allData: payload.rows
    }
    // console.log("eventList--------------", data)
    return data;
  }
  catch (e) {
    console.log("Error ============>", e)
  }
}



export function filterByDates(payload) {
  try {
    if(payload.rows && payload.rows.length) {
      let new_array = [];
      for (let i = 0; i < payload.rows.length; i++) {
        let a = payload.rows
        let event = a[i]
        let startDate = moment(event.created_date).locale(`${getCookieByValue("lang") === "wel" ? "cy" : "en"}`, localization).format(`${config.dateDayFormat}`)
        event.startDate = startDate
        new_array.push(event);
      }
      return new_array;
    }
  }
  catch (e) {
    console.log("Error ============>", e)
  }
}


export function createLables(keys) {
  try {
    let lang = getCookieByValue("lang")
    let data
    if(lang === "wel") {
      var output = {};
      output = jsonConcat(output, en);
      output = jsonConcat(output, wel);
      data = output  //Object.assign(wel, en)}
    }
    else data = en

    localStorage.setItem('languageData', JSON.stringify(data));
  }
  catch (e) {
    console.log("Error ============>", e)
  }
}

function jsonConcat(o1, o2) {
  for (var key in o2) {
    if(o2[key]) o1[key] = o2[key];
  }
  return o1;
 }


export async function  changeLanguage(key) {
  try {
    await setAllCookie("lang", key)
    await createLables()
  }
  catch (e) {
    console.log("Error ============>", e)
  }
}


export function generateLabels(keys) {
  try {
    let data = JSON.parse(localStorage.getItem("languageData"));
    return data && data[keys]

  }
  catch (e) {
    console.log("Error ============>", e)
  }
}


export async function groupMessagesByDates(payload) {
  try {    
    let filter = await filterByDates(payload)
    // console.log("aaaaaaa--------------", filter)

    let messageList = lodash.chain(filter)
    .groupBy('startDate')
    .toPairs()
    .map(function (currentItem) { return lodash.zipObject(['date', 'data'], currentItem) }).value();

    // let sortedResult 	= lodash.sortBy(eventList, function(item) {
    //   return new Date( item.date );
    // });
    let data = {
      rows: messageList,
      count: payload.count,
      allData: payload.rows
    }
    // console.log("eventList--------------", data)
    return data;
  }
  catch (e) {
    console.log("Error ============>", e)
  }
}