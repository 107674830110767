import React from "react";
import config from "../../../config";

export default () => {
  return (
    <div className="loader"> 
      <div className="logo-block">
        {/*<img src={`${config.cloudUrl}loader.gif`} width="36" alt="" />*/}
        <img src={`${config.cloudUrl}spinner.png`} width="36" alt="" />
      </div>
      <div className="text-block">Loading...</div>
    </div>
  );
};
