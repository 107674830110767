import { api, catchHandler } from "../../../helpers/axios";
import config from "../../../../config";
import { objToUrlParams,generateRandomKey} from "../../../helpers";

export function listMedications(data) {
    return api()
      .get(`${config.routes.listMedications}?${objToUrlParams(data)}`)
      .then(res => res.data)
      .catch(catchHandler)
}
export function getMedications(data) {
    return api()
        .get(`${config.routes.getMedication}?${objToUrlParams(data)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getMedicationByTime(data) {
    return api()
        .get(`${config.routes.getMedicationByTime}?${objToUrlParams(data)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function addMedications(data){
    data.csrf = generateRandomKey();
    return api()
        .post(`${config.routes.addMedications}`, data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function editMedications(data){
    return api()
        .put(`${config.routes.editMedications}`, data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function deleteMedications(data) {
    return api()
        .delete(config.routes.getMedication, {data: data})
        .then(res => res.data)
        .catch(catchHandler)
}
export function listMedicationLocations(data) {
    return api()
        .get(config.routes.listMedicationLocations, { params: data })
        .then(res => res.data)
        .catch(catchHandler)
}
export function addNewLocation(data){
    return api()
        .post(`${config.routes.location}`, data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function removeLocation(data){
    return api()
        .delete(`${config.routes.location}`, {data})
        .then(res => res.data)
        .catch(catchHandler)
}
export function editLocation(data){
    return api()
        .put(`${config.routes.location}`, data)
        .then(res => res.data)
        .catch(catchHandler)
}