import axios from "axios";
import config from "../../config";
import { history } from "../store";
import {getJsonCookies, getCookieByValue , removeCircleCookie, clearCookies } from './utility';

const customHeader = () => ({
  Authorization: getJsonCookies() || undefined,
  lang: getCookieByValue("lang") ? getCookieByValue("lang") : "en",

});

export function api() {
  let opts = {
    baseURL: config.api.trim(),
    headers: customHeader(),
    withCredentials: true
  };
  return axios.create(opts);
}

export function catchHandler(e) {
  let res = JSON.parse(JSON.stringify(e)).response;
  // if (res.status === 404 && res.data && res.data.message === "connect ETIMEDOUT") {
  //   throw new Error("Please Relogin");
  // }
  if (!res) throw new Error("Server restarting, please try again later.");

  // if (getJsonCookies() && res.status === 401) {
  if (res.status === 401) {
    clearCookies()
    removeCircleCookie();
    localStorage.removeItem('acl');
    localStorage.removeItem('sortList');
    history.push("/login");
    window.location.reload();
    // throw new Error("Please Relogin");
  }
  throw res;
}
