import notification from '../../components/notification';
import * as service from "./service";
import {generateLabels} from "../../helpers";
import { history } from '../../store';

export default {
  state: { 
    promocodes: {},
    promocode: {}
   },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onError(state, { data = {} }) {
      notification("error", data.message);
      return {
        ...state,
        loading: false
      };
    },
    onCreatePromoCodesSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_PROMO_CREATE"));
      history.push("/promo-code/list")
      return {
        ...state, 
        loading: false,
      }
    },
    onGetPromoCodesSuccess(state, data) {
      return {
        ...state, 
        loading: false,
        promocodes: data
      }
    },
    onPromoCodeDetailSuccess(state, data) {
      return {
        ...state, 
        loading: false,
        promocode: data
      }
    },
    onEditPromoCodesSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_PROMO_UPDATE"));
      history.push("/promo-code/list")
      return {
        ...state, 
        loading: false,
      }
    },
    onDeletePromoCodesSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_PROMO_DELETE"));
      // history.push("/promo-code/list")
      return {
        ...state, 
        loading: false,
      }
    },
    
  },
  effects: {
    async createPromoCodes(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createPromoCodes(payload);
        this.onCreatePromoCodesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getPromoCodes(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getPromoCodes(payload);
        this.onGetPromoCodesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async promoCodeDetails(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.promoCodeDetails(payload);
        this.onPromoCodeDetailSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async editPromoCodes(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.editPromoCodes(payload);
        this.onEditPromoCodesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async deletePromoCodes(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.editPromoCodes(payload);
        this.onDeletePromoCodesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },


    
    
  }
};
