import 'core-js/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import "./assets/css/antd.css";
import './assets/scss/style.scss';

ReactDOM.render(<App/>, document.getElementById('root'));
unregister();
