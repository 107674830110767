import * as service from "./service";
import notification from '../../../components/notification';
import {capitalize, generateLabels} from "../../../helpers";

export default {
    state: {
      medications:{},
      medication:{}
    },
    reducers: {
        onRequest(state) {
            return {
                ...state,
                loading: true
            };
        },
        onError(state, { data = {} }) {
            notification("error", capitalize(data.message));
            return {
                ...state,
                loading: false
            };
        },
        onListMedicationsSuccess(state, data) {
            return {
              ...state,
              loading: false,
              medications: data,
            }
        },
        onGetMedicationsSuccess(state, data){
            return {
                ...state, 
                loading: false,
                medication: data
            }
        },
        onGetMedicationByTimeSuccess(state, data) {
            return {
              ...state,
              loading: false,
              medications: data
            }
        },
        onGetCurrentMedicationsSuccess(state, data) {
            return {
              ...state,
              loading: false,
              currentMedications: data
            }
        },
        onGetPastMedicationsSuccess(state, data) {
            return {
              ...state,
              loading: false,
              pastMedications: data
            }
        },
        onAddMedicationsSuccess(state, data) {
            notification('success', generateLabels("TOASTERS_MED_ADDED"))
            return {
                ...state,
                loading: false,
                contact: data
            }
        },
        onEditMedicationsSuccess(state, data) {
            notification('success', generateLabels("MEDICATION_UPDATED"))
            return {
                ...state, 
                loading: false,
                contact: data,
            }
        },
        onDeleteMedicationsSuccess(state, data) {
            notification('success', generateLabels("MEDICATION_DELETED"))
            return {
                ...state, 
                loading: false,
            }
        },
        onAddNewLocationSuccess(state, data) {
          notification('success', "Added new location")
          return {
              ...state, 
              loading: false,
          }
      },
      onDeleteLocationSuccess(state, data) {
        notification('success', "Location removed successfully")
        return {
            ...state, 
            loading: false,
        }
      },
      onEditLocationSuccess(state, data) {
          notification('success', "Location edited")
          return {
              ...state, 
              loading: false,
          }
      },
        onListMedicationLocationsSuccess (state, data) {
            return {
                ...state,
                loading: false,
                medicationLocations: data.rows
            }
        }
    },
    effects: {
        async listMedications(payload, rootState) {
            this.onRequest()
            try{
              let res = await service.listMedications(payload)
              this.onListMedicationsSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async getMedications(payload, rootState) {
            this.onRequest()
            try{
              let res = await service.getMedications(payload)
              this.onGetMedicationsSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async getCurrentMedications(payload, rootState) {
            this.onRequest()
            try{
              let res = await service.listMedications({...payload, filter_by: 'current'})
              this.onGetCurrentMedicationsSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async getPastMedications(payload, rootState) {
            try {
              let res = await service.listMedications({...payload, filter_by: 'past'})
              this.onGetPastMedicationsSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async getMedicationByTime(payload, rootState) {
            this.onRequest()
            try {
              let res = await service.getMedicationByTime(payload)
              this.onGetMedicationByTimeSuccess(res) 
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async addMedications(payload, rootState) {
            this.onRequest()
            try{
              let res = await service.addMedications(payload)
              this.onAddMedicationsSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async editMedications(payload, rootState) {
            this.onRequest()
            try{
              let res = await service.editMedications(payload)
              this.onEditMedicationsSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async deleteMedications(payload, rootState) {
            this.onRequest()
            try{
              let res = await service.deleteMedications(payload)
              this.onDeleteMedicationsSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async listMedicationLocations (payload, rootState) {
            this.onRequest()
            try {
              let res = await service.listMedicationLocations(payload)
              this.onListMedicationLocationsSuccess(res)
              return res
            }
            catch(err) {
              this.onError(err)
            }
          },
          async addNewLocation (payload, rootState) {
            this.onRequest()
            try {
              let res = await service.addNewLocation(payload)
              this.onAddNewLocationSuccess(res)
              return res
            }
            catch(err) {
              this.onError(err)
            }
          },
          async editLocation (payload, rootState) {
            this.onRequest()
            try {
              let res = await service.editLocation(payload)
              this.onEditLocationSuccess(res)
              return res
            }
            catch(err) {
              this.onError(err)
            }
          },
          async removeLocation (payload, rootState) {
            console.log("payload ======", payload)
            this.onRequest()
            try {
              let res = await service.removeLocation(payload)
              this.onDeleteLocationSuccess(res)
              return res
            }
            catch(err) {
              this.onError(err)
            }
          }
    }
}