import { api, catchHandler } from "../../../helpers/axios";
import config from "../../../../config";
import { objToUrlParams } from "../../../helpers";

// export function listDevices(data) {
//   return api() 
//     .get(`${config.routes.listDevices}?${objToUrlParams(data)}`)
//     .then(res => res.data)
//     .catch(catchHandler)
// }

export function listDevices(data) {
  return api() 
    .get(`${config.routes.deviceService}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export function listCirclesConnected(data) {
  return api() 
    .get(`${config.routes.circleService}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export function listServices(data) {
  return api() 
    .get(`${config.routes.service}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export function deleteDevice(data) {
  return api()
    .delete(config.routes.deleteDevice, {data: data})
    .then(res => res.data)
    .catch(catchHandler)
}