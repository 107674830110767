import * as service from "./service";
import notification from '../../components/notification';
import {capitalize, generateLabels} from "../../helpers";

export default {
    state: {
        allPermissions:{},
        siteSettings:{}
    },
    reducers: {
        onRequest(state) {
            return {
                ...state,
                loading: true
            };
        },
        onError(state, { data = {} }) {
            notification("error", capitalize(data.message));
            return {
                ...state,
                loading: false
            };
        },
        onGetDigestsSuccess(state, data) {
            return {
                ...state,
                loading: false,
                digests: data
            }
        },
        onSaveDigestsSuccess(state, data){
            notification("success", generateLabels("TOASTERS_NOTI_CHANGED"));
            return {
                ...state,
                loading: false
            }
        },
        onToggleMemberStatusSuccess(state, data){
            return {
                ...state,
                loading: false
            }
        },
        onDeleteMemberSuccess(state, data){
            return {
                ...state,
                loading: false
            }
        },
        onListPermissionSuccess(state, data){
            return {
                ...state,
                loading: false,
                allPermissions:data
            }
        },
        onTogglePermissionSuccess(state, data){
            return {
                ...state,
                loading: false
            }
        },
        onToggleCircleAdminSuccess(state, data) {
            return {
                ...state,
                loading: false
            }
        },
        onGetSiteSettingsSuccess(state, data){
            return {
                ...state,
                loading: false,
                siteSettings:data
            }
        },
        onUpdateSiteSettingsSuccess(state, data){
            notification("success", generateLabels("TOASTERS_SITE_UPDATE"));
            return {
                ...state,
                loading: false,
            }
        },
    },
    effects: {
        async getDigests(payload, rootState){
            this.onRequest()
            try{
                let res = await service.getDigests(payload)
                this.onGetDigestsSuccess(res)
                return res
            }catch(e) {
                this.onError(e)
            }
        },
        async saveDigests(payload, rootState){
            this.onRequest()
            try{
                let res = await service.saveDigests(payload)
                this.onSaveDigestsSuccess(res)
                return res
            }catch(e) {
                this.onError(e)
            }
        },
        async toggleMemberStatus(payload, rootState){
            this.onRequest()
            try{
                let res = await service.toggleMemberStatus(payload)
                this.onToggleMemberStatusSuccess(res)
                return res
            }catch(e) {
                this.onError(e)
            }
        },
        async deleteMember(payload, rootState){
            this.onRequest()
            try{
                let res = await service.deleteMember(payload)
                this.onDeleteMemberSuccess(res)
                return res
            }catch(e) {
                this.onError(e)
            }
        },
        async listAllPermissions(payload, rootState){
            this.onRequest()
            try{
                let res = await service.listAllPermissions(payload)
                this.onListPermissionSuccess(res)
                return res
            }catch(e) {
                this.onError(e)
            }
        },
        async togglePermissions(payload, rootState){
            this.onRequest()
            try{
                let res = await service.togglePermissions(payload)
                this.onTogglePermissionSuccess(res)
                return res
            }catch(e) {
                this.onError(e)
            }
        },
        async toggleCircleAdminPermission(payload, rootState) {
            this.onRequest()
            try{
                let res = await service.toggleCircleAdminPermission(payload)
                this.onToggleCircleAdminSuccess(res)
                return res
            }
            catch(e) {
                this.onError(e)
            }
        },
        async getSiteSettings(payload, rootState){
            this.onRequest()
            try{
                let res = await service.getSiteSettings(payload)
                this.onGetSiteSettingsSuccess(res)
                return res
            }catch(e) {
                this.onError(e)
            }
        },
        async updateSiteSettings(payload, rootState){
            this.onRequest()
            try{
                let res = await service.updateSiteSettings(payload)
                this.onUpdateSiteSettingsSuccess(res)
                return res
            }catch(e) {
                this.onError(e)
            }
        }

    }
}