import notification from '../../components/notification';
import * as service from "./service";
import {generateLabels} from "../../helpers";
import { history } from '../../store';

export default {
  state: {
    redeemCodes: {},
    allServices: {},
    serviceAdminDetails: {}

  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onError(state, { data = {} }) {
      notification("error", data.message);
      return {
        ...state,
        loading: false
      };
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false,
      }
    },
    onCreateRedeemCodesSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_INVITATION_SEND"));
      return {
        ...state,
        loading: false,
      }
    },
    onServiceAdminSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_INVITATION_SEND"));
      history.push('/partners')
      return {
        ...state,
        loading: false,
      }
    },
    onCreateServiceAdminSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_SER_ADMIN_CREATE"));
      return {
        ...state,
        loading: false,
      }
    },

    onListRedeemCodesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        redeemCodes: data
      }
    },
    onResendRedeemCodesSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_RESEND_CODE"));
      return {
        ...state,
        loading: false,
      }
    },
    onDeleteRedeemCodesSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_CODE_DELETE"));
      return {
        ...state,
        loading: false,
      }
    },
    onCreateJoinServiceSuccess(state, data) {
      return {
        ...state,
        loading: false,
      }
    },
    ondeleteJoinServiceSuccess(state, data) {
      return {
        ...state,
        loading: false,
      }
    },
    onGetAllServicesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allServices: data
      }
    },
    onGetServicesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        serviceList: data
      }
    },
    onCreateServiceAndServiceAdminSuccess(state, data) {
      notification("success", "Service Created Successfully");
      history.push("/services")
      return {
        ...state,
        loading: false,
      }
    },
    onEditServiceAndServiceAdminSuccess(state, data) {
      notification("success", "Service Updates Successfully");
      history.push("/services")
      return {
        ...state,
        loading: false,
      }
    },
    
    onGetServiceAndServiceAdminSuccess(state, data) {
      return {
        ...state,
        loading: false,
        serviceAdminDetails: data

      }
    },
    onResendMagicLinkSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_LINK_SUCESS"));
      return {
        ...state,
        loading: false,

      }
    },
    onDeleteServiceAdminSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_SER_ADMIN_DELETE"));
      return {
        ...state,
        loading: false,
      }
    },

    
    




  },
  effects: {
    async createRedeemCodes(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createRedeemCodes(payload);
        this.onCreateRedeemCodesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async serviceAdmin(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.serviceAdmin(payload);
        this.onServiceAdminSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },

    async createServiceAdmin(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createServiceAdmin(payload);
        this.onCreateServiceAdminSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },

    async listRedeemCodes(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listRedeemCodes(payload);
        this.onListRedeemCodesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async resendRedeemCodes(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.resendRedeemCodes(payload);
        this.onResendRedeemCodesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async deleteRedeemCodes(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.deleteRedeemCodes(payload);
        this.onDeleteRedeemCodesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getAllServices(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getAllServices(payload);
        this.onGetAllServicesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getServices(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getAllServices(payload);
        this.onGetServicesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async createJoinService(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createJoinService(payload);
        this.onCreateJoinServiceSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async deleteJoinService(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.deleteJoinService(payload);
        this.ondeleteJoinServiceSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },

    async createServiceAndServiceAdmin(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.createServiceAndServiceAdmin(payload)
        this.onCreateServiceAndServiceAdminSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async editServiceAndServiceAdmin(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.editServiceAndServiceAdmin(payload)
        this.onEditServiceAndServiceAdminSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getServiceAndServiceAdmin(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getServiceAndServiceAdmin(payload)
        this.onGetServiceAndServiceAdminSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteServiceAdmin(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.deleteServiceAdmin(payload)
        this.onDeleteServiceAdminSuccess(res)
        this.getAllServices({ limit: 10 })
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async resendMagicLink(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.resendMagicLink(payload)
        this.onResendMagicLinkSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    

  }
};
