import React from 'react'
import { Modal, Row, Col } from 'antd'
import config from "../../../config";
import { CardElement, injectStripe, } from 'react-stripe-elements'
//import { url } from 'inspector';
import * as service from '../../containers/circles/service'
import notification from '../../components/notification';
import { capitalize, generateLabels } from "../../helpers";
import { history } from '../../store';

// const FormItem = Form.Item

// const { Option } = Select;
const currentDate = new Date();
// const years = new Array(15).fill(null).map((y, i) => {
//   return (currentDate.getFullYear() + i).toString();
// });
// const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
// const months = new Array(12).fill(null).map((y, i) => {
//   let month = (1 + i).toString()
//   return {month:('0' + month).slice(-2),name :monthNames[i]};
// });
// const valueFormatter = (e) => {
//   let value = e.target.value.split(" ").join("");
//    if (value.length > 0) {
//      value = value.match(new RegExp('.{1,4}', 'g')).join(" ");
//    }
//  return value;
// };

// const CreateFormWrapper = Form.create()(PaymentForm)

class PaymentModal extends React.Component {

  constructor() {
    super()
    this.handleServerResponse = this.handleServerResponse.bind(this)
    this.state = {
      // visible: this.props.visible,
      cardImg: 'card',
      expMonth: (currentDate.getMonth() + 1).toString(),
      expYear: currentDate.getFullYear().toString(),
      paying: false
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props && this.props.visible) this.setState({ visible: this.props.visible })
  }

  // open() {
  //   this.showModal()
  //   return new Promise((resolve, reject) => {
  //     this.resolve = resolve
  //     this.reject = reject
  //   })
  // }
  showModal() {
    this.setState({ visible: true })
  }
  cancelModal() {
    this.setState({ visible: false })
    this.props.closeModal()
    if(this.props.circle && this.props.circle.circle_slug)
      history.push(`/circle/${this.props.circle.circle_slug}/overview`)
    
    // const form = this.form;
    // form.resetFields();
  }
  // handleSubmit() {
  //   const form = this.form
  //   form.validateFields((err, values)=> {
  //     if(err) return
  //     // form.resetFields();
  //     this.setState({visible: false})
  //     this.resolve(values)
  //   })
  // }
  async handleSubmit(ev) {
    try {
      this.setState({paying: true})
      let response = await this.props.stripe.createPaymentMethod("card")
      if (response.error) {
        //notify "error"
        notification("error", response.error.message)
        this.setState({paying: false})
      }
      else {
        
        //let res = await makeFirstApi({payment_method_id: response.paymentMethod.id})
        let res = await service.addPayment({ payment_method_id: response.paymentMethod.id, circle_id: this.props.circle_id ? this.props.circle_id : null, subscription_id: 1 })
        this.handleServerResponse(res)
      }
    } catch (e) {
      console.log("Error =============", e.data)
      this.setState({paying: false})
      this.props.closeModal()
      notification("error", e.data.message)
      this.props.errHandling()
    }
  }

  async handleServerResponse(response) {
    if (response.error) {
      //notify "error"
      notification("error", response.error.message)
      this.setState({paying: false})
    }
    else if (response.requires_action) {
      this.props.stripe.handleCardAction(response.payment_intent_client_secret)
        .then(async (result) => {
          if (result.error) {
            //notify "error"
            notification("error", result.error.message)
            this.setState({paying: false})
          }
          else if (result.payment_status === 'complete') {
            notification("success", generateLabels("TOASTERS_PAYMENT_SUCCESS"))
            this.setState({paying: false})
            this.props.closeModal()
            this.props.onPaymentSuccess()
          }
          else {
            let res = await service.addPayment({ payment_intent_id: result.paymentIntent.id, circle_id: this.props.circle_id ? this.props.circle_id : result.circle_id, subscription_id: 1 })
            if (res.payment_status === 'complete') {
              notification("success", generateLabels("TOASTERS_PAYMENT_SUCCESS"))
              this.setState({paying: false})
              this.props.closeModal()
              this.props.onPaymentSuccess()
            }
            else {
              notification("error", res.error.message)
              this.setState({paying: false})
            }
          }
        })
        .catch(e => {
          let err = e && e.message ? e.message : e && e.e && e.e.message ? e.e.message : 'Please try again later';
          notification("error", capitalize(err));
        })
    }
    else if (response.payment_status === 'complete') {
      notification("success", generateLabels("TOASTERS_PAYMENT_SUCCESS"))
      this.props.closeModal()
      this.props.onPaymentSuccess()
    }
  }

  showFreeAccessModal() {
    // let data = { status: true }
    // this.setState({ visible: false })
    // this.resolve(data)
    this.props.closeModal()
    this.props.showFreeAccessModal()
  }

  handleChange = (e) => {
    let cardNumber = e.target.value;
    cardNumber = cardNumber.replace(/\D/g, '');
    let visaRegEx = new RegExp('^4[0-9]{0,}$');
    let mastercardRegex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$');
    let maestroRegex = new RegExp('^(5[06789]|6)[0-9]{0,}$');
    let discoverRegex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
    let jcbRegex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$');
    let amexRegex = new RegExp('^3[47][0-9]{0,}$');

    if (cardNumber.match(visaRegEx))
      this.setState({ cardImg: 'visa' })
    else if (cardNumber.match(mastercardRegex))
      this.setState({ cardImg: 'master' })
    else if (cardNumber.match(maestroRegex))
      this.setState({ cardImg: 'maestro' })
    else if (cardNumber.match(discoverRegex))
      this.setState({ cardImg: 'discover' })
    else if (cardNumber.match(jcbRegex))
      this.setState({ cardImg: 'jcb' })
    else if (cardNumber.match(amexRegex))
      this.setState({ cardImg: 'amex' })
    else
      this.setState({ cardImg: 'card' })

  }
  render() {
    return (
      <Modal
        className="simple-modal create-new-circle-modal"
        title={generateLabels("PURCHASE_PAYMENT_TITLE")}
        visible={this.props.visible}
        okButtonProps={{disabled: this.state.paying}}
        // confirmLoading={this.state.paying}
        okText={generateLabels("PURCHASE_PROCEED_BUTTON").replace("#FIELD#", this.props.subscription && this.props.subscription.price ? this.props.subscription.price : null)}
        onOk={this.handleSubmit.bind(this)}
        onCancel={this.cancelModal.bind(this)}
        cancelText={generateLabels("PURCHASE_CANCEL_BUTTON")}
        cancelButtonProps={{ className: 'cancel-btn' }}>
        <div className="right-free-access-block">
          <div className="media-block">
            <img src={`${config.cloudUrl}giftbox.svg`} alt="" />
          </div>
          <div className="question-block text-bold">{generateLabels("PURCHASE_FREE_ACCESSCODE_LINK")} <button className="btn item-link" onClick={this.showFreeAccessModal.bind(this)}>{generateLabels("PURCHASE_FREE_ACCESSCODE_LINK_TWO")}</button> </div>
          <p className="para-block">{generateLabels("PURCHASE_FREE_ACCESS_CODE_TEXT")}</p>
        </div>
        {/* <CreateFormWrapper ref = {ref => (this.form = ref)} self={this} handleChange={this.handleChange.bind(this)} /> */}
        {/* <StripeProvider apiKey="pk_test_TYooMQauvdEDq54NiTphI7jx">
          <Elements> */}
        <StripeForm />
        {/* </Elements>
        </StripeProvider> */}
      </Modal>
    )
  }
}

function StripeForm(props) {
  return (
    <React.Fragment>
      <Row gutter={14} className="checkout">
        <CardElement hidePostalCode={true} />
      </Row>
      <Row gutter={14} className="circle-security-wrapper" type="flex">
        <Col xs={24} md={8} className="ant-col">
          <div className="circle-security-item">
            <div className="media-block"><img src={`${config.cloudUrl}icon-credit-card.svg`} alt="" /></div>
            <p>{generateLabels("PURCHASE_TEXT_ONE")}</p>
          </div>
        </Col>
        <Col xs={24} md={8} className="ant-col">
          <div className="circle-security-item">
            <div className="media-block"><img src={`${config.cloudUrl}icon-warning.svg`} alt="" /></div>
            <p>{generateLabels("PURCHASE_TEXT_TWO")}</p>
          </div>
        </Col>
        <Col xs={24} md={8} className="ant-col">
          <div className="circle-security-item">
            <div className="media-block"><img src={`${config.cloudUrl}icon-lock.svg`} alt="" /></div>
            <p>{generateLabels("PURCHASE_TEXT_THREE")}</p>
          </div>
        </Col>
      </Row>
      <div className="payment-wrapper">
        <img src={`${config.cloudUrl}icon-master-card.png`} alt="" />
        <img src={`${config.cloudUrl}icon-visa-card.png`} alt="" />
        <img src={`${config.cloudUrl}icon-amex-card.png`} alt="" />
        <img src={`${config.cloudUrl}icon-stripe-card.png`} alt="" />
      </div>
    </React.Fragment>
  )
}

// function PaymentForm(props) {
//   const { form,handleChange} = props;
//   const {getFieldDecorator} = form
//   return (
//     <Form layout="vertical" className="simple-form">
//     <Row gutter={14}>
//       <Col xs={24} md={12}>
//       <FormItem className="form-group card-form-group"  label="Your card number">
//         {getFieldDecorator('number', {
//           getValueFromEvent: valueFormatter,
//           rules: [{ required: true, message: 'Please input your Card Number!' },{min:19,message:'Invalid card number'},{max:19,message:'Invalid card number'}],
//         })(
//           <Input onChange={handleChange}  prefix={<span style={{backgroundImage: `url(${config.cloudUrl}cards/${props.self.state.cardImg}.svg)`}} className="icon-block"/>} placeholder="Card Number" type="text"  name="number" />
//         )}

//         {/*
//           payment card type prefix classes
//           1. icon-card (default)
//           2. icon-visa
//           3. icon-discover
//           4. icon-jcb
//           5. icon-maestro
//           6. icon-amex
//           7. icon-mastercard
//         */}

//       </FormItem>
//       </Col>
//       <Col xs={12} md={8} lg={6}>
//         <FormItem
//         className="hide-required-star"
//         label={
//           <div className="label-has-tooltip">
//               <span className="label-text">CVV *</span>
//               <Tooltip title="Your card verification code">
//                 <span className="icon-info help-icon" />
//               </Tooltip>
//             </div>
//           }>
//           {getFieldDecorator('cvc', {
//           rules: [{ required: true, message: 'CVV!' },{min:3,message:'Invalid CVV/CVC'},{max:3,message:'Invalid CVV/CVC'}],
//           })(
//             <Input placeholder="CVV" type="text"  name="cvv"  />
//           )}  
//         </FormItem>
//       </Col>
//       <Col xs={24} md={24}>
//         <label className="ant-form-item-label">Your card expiration</label>
//       </Col>
//       <Col xs={24} sm={8} lg={7} xl={6}>
//         <div id="monthSelect">
//           <FormItem id="monthSelect">
//           {getFieldDecorator('exp_month', {
//             initialValue: props.self.state.expMonth,
//             rules: [{ required: true, message: 'Please select month!' }],
//           })(
//             <Select placeholder="Month (mm)" suffixIcon={<span className="icon-caret-down"></span>} getPopupContainer={() => document.getElementById('monthSelect')}>
//                 {months.map(data => (
//                   <Option key={data.month} value={data.month}>
//                     {`${data.name} (${data.month}) `}
//                   </Option>
//                 ))}
//             </Select>
//           )}
//           </FormItem>
//         </div>
//       </Col>
//       <Col xs={24} sm={8} lg={7} xl={6}>
//         <div id="dateSelect">
//           <FormItem>
//           {getFieldDecorator('exp_year', {
//             initialValue: props.self.state.expYear,
//             rules: [{ required: true, message: 'Please select year!' }],
//           })(
//             <Select placeholder="Year (yyyy)" suffixIcon={<span className="icon-caret-down"></span>} getPopupContainer={() => document.getElementById('dateSelect')}>
//                 {years.map(name => (
//                   <Option key={name} value={name}>
//                     {name}
//                   </Option>
//                 ))}
//             </Select>
//           )}
//           </FormItem>
//         </div>
//       </Col> 
//     </Row>
//     <Row gutter={14} className="circle-security-wrapper" type="flex">
//       <Col xs={24} md={8} className="ant-col">
//         <div className="circle-security-item">
//           <div className="media-block"><img src={`${config.cloudUrl}icon-credit-card.svg`} alt="" /></div>
//           <p>Your payment is handled by Stripe using the strictest PCI requirements.</p>
//         </div>
//       </Col> 
//       <Col xs={24} md={8} className="ant-col">
//         <div className="circle-security-item">
//           <div className="media-block"><img src={`${config.cloudUrl}icon-warning.svg`} alt="" /></div>
//           <p>Neither Stripe nor Carers UK store your debit or credit card details.</p>
//         </div>
//       </Col> 
//       <Col xs={24} md={8} className="ant-col">
//         <div className="circle-security-item">
//           <div className="media-block"><img src={`${config.cloudUrl}icon-lock.svg`} alt="" /></div>
//           <p>Your data is transmitted via an encrypted connection to a secure server.</p>
//         </div>
//       </Col> 
//     </Row>
//     <div className="payment-wrapper">
//       <img src={`${config.cloudUrl}icon-master-card.png`} alt="" />
//       <img src={`${config.cloudUrl}icon-visa-card.png`} alt="" />
//       <img src={`${config.cloudUrl}icon-amex-card.png`} alt="" />
//       <img src={`${config.cloudUrl}icon-stripe-card.png`} alt="" />
//     </div>
//   </Form>
//   )
// }

export default injectStripe(PaymentModal)
