import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Loader from '../components/loader'
import ContentLoader from "react-content-loader";

export const MessageLoader = () => (
  <ContentLoader
    height={320}
    width={'100%'}
    className="content-loader"
    style={{ height: 320 }}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#dddddd"
  >
    <rect x="60" y="5" rx="3" ry="3" width="200" height="10" />
    <rect x="60" y="25" rx="3" ry="3" width="85" height="10" />
    <rect x="0" y="60" rx="3" ry="3" width="100%" height="10" />
    <rect x="0" y="80" rx="3" ry="3" width="100%" height="10" />
    <rect x="0" y="100" rx="3" ry="3" width="50%" height="10" />
    <circle cx="20" cy="20" r="20" />
    <rect x="0" y="120" rx="0" ry="0" width="152" height="152" />
    <rect x="0" y="300" rx="0" ry="0" width="100%" height="1" />
  </ContentLoader>
)

const DeviceListLoader = () => (
  <ContentLoader
    height={120}
    width={'100%'}
    className="content-loader"
    style={{ height: 120 }}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#dddddd"
  >
    <rect x="124" y="5" rx="3" ry="3" width="80%" height="10" />
    <rect x="124" y="25" rx="3" ry="3" width="30%" height="10" />
    <rect x="124" y="60" rx="3" ry="3" width="80" height="8" />
    <rect x="0" y="0" rx="0" ry="0" width="104" height="74" />
    <rect x="0" y="100" rx="0" ry="0" width="100%" height="1" />
  </ContentLoader>
)
const OverviewListLoader = () => (
  <ContentLoader
    height={420}
    width={'100%'}
    className="content-loader"
    style={{ height: 420 }}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#dddddd"
  >
    <rect x="60" y="5" rx="3" ry="3" width="200" height="10" />
    <rect x="60" y="25" rx="3" ry="3" width="85" height="10" />
    <rect x="0" y="60" rx="3" ry="3" width="100%" height="10" />
    <rect x="0" y="80" rx="3" ry="3" width="100%" height="10" />
    <rect x="0" y="100" rx="3" ry="3" width="50%" height="10" />
    <circle cx="20" cy="20" r="20" />
    <rect x="0" y="120" rx="0" ry="0" width="240" height="240" />
    <rect x="0" y="390" rx="0" ry="0" width="100%" height="1" />
  </ContentLoader>
);


const MedicationListLoader = () => (
  <ContentLoader
    height={120}
    width={'100%'}
    className="content-loader"
    style={{ height: 120 }}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#dddddd"
  >
    <rect x="124" y="5" rx="3" ry="3" width="80%" height="10" />
    <rect x="124" y="25" rx="3" ry="3" width="30%" height="10" />
    <rect x="124" y="60" rx="3" ry="3" width="80" height="8" />
    <rect x="0" y="0" rx="0" ry="0" width="104" height="74" />
    <rect x="0" y="100" rx="0" ry="0" width="100%" height="1" />
  </ContentLoader>
)

const NotesListLoader = () => (
  <ContentLoader
    height={120}
    width={'100%'}
    className="content-loader"
    style={{ height: 120 }}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#dddddd"
  >
    <rect x="124" y="5" rx="3" ry="3" width="80%" height="10" />
    <rect x="124" y="25" rx="3" ry="3" width="30%" height="10" />
    <rect x="124" y="60" rx="3" ry="3" width="80" height="8" />
    <rect x="0" y="0" rx="0" ry="0" width="104" height="74" />
    <rect x="0" y="100" rx="0" ry="0" width="100%" height="1" />
  </ContentLoader>
)

const TaskListLoader = () => (
  <ContentLoader
    height={170}
    width={'100%'}
    style={{ height: 170 }}
    className="content-loader"
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#dddddd"
  >
    <rect x="60" y="5" rx="3" ry="3" width="200" height="10" />
    <rect x="60" y="25" rx="3" ry="3" width="85" height="10" />
    <rect x="0" y="60" rx="3" ry="3" width="100%" height="10" />
    <rect x="0" y="80" rx="3" ry="3" width="100%" height="10" />
    <rect x="0" y="100" rx="3" ry="3" width="50%" height="10" />
    <circle cx="20" cy="20" r="20" />
    <rect x="0" y="140" rx="0" ry="0" width="100%" height="1" />
  </ContentLoader>
)




export default function asyncComponent(importComponent, title) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
        loading: true
      };
    }
    // componentWillMount() {
    // }
    componentWillUnmount() {
      this.mounted = false;
    }
    async UNSAFE_componentWillMount() {
      if (window.location.pathname === '/') this.setState({ loading: false })
      this.mounted = true;
      const { default: Component } = await importComponent();
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />,
          loading: false
        });
      }
    }
    render() {
      const Component =
        <main >
          <Helmet>
            <title>{title ? title : "Jointly App"}</title>
          </Helmet>
          {this.state.component}
        </main>
        ||
        <main className="main-inner-wrapper">
          <Helmet>
            <title>{title ? title : "Jointly App"}</title>
          </Helmet>
          <div className="container">
          </div>
        </main>;
      return this.state.loading ?

        title && title === "Jointly | Medications" ?
          <div className="main-right-container no-sidebar">
            <div className="card-widget-content has-padding">
              <MedicationListLoader />
              <MedicationListLoader />
              <MedicationListLoader />
            </div>
          </div> :
          title && title === "Jointly | Notes" ?
            <div className="main-right-container no-sidebar"> 
              <div className="card-widget-content has-padding">
                <NotesListLoader />
                <NotesListLoader />
                <NotesListLoader />
              </div> 
            </div> :
            title && title === "Jointly | Overview" ?
              <div className="main-right-container no-sidebar">
                <div className="card-widget-content has-padding">
                  <OverviewListLoader />
                  <OverviewListLoader />
                  <OverviewListLoader />
                </div>
              </div> :
              title && title === "Jointly | Tasks" ?
                <div className="main-right-container no-sidebar">
                  <div className="card-widget-content has-padding">
                    <TaskListLoader />
                    <TaskListLoader />
                    <TaskListLoader />
                  </div>
                </div> :
                title && title === "Jointly | Messages" ?
                  <div className="main-right-container no-sidebar">
                    <div className="card-widget-content has-padding">
                      <MessageLoader />
                      <MessageLoader />
                      <MessageLoader />
                    </div> 
                  </div> :
                  title && title === "Jointly | Devices" ?
                    <div className="main-right-container no-sidebar">
                      <div className="card-widget-content has-padding">
                        <DeviceListLoader />
                        <DeviceListLoader />
                        <DeviceListLoader />
                      </div>
                    </div> :
                    <div className="page-loader">
                      <Loader />
                    </div>
        : Component;
    }
  }
  return AsyncFunc;
}
