import { api, catchHandler } from "../../helpers/axios";
import config from "../../../config";
import { objToUrlParams } from "../../helpers";

export function dashboardActivities(data){
    return api()
        .get(`${config.routes.listActivities}?${objToUrlParams(data)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
