import { api, catchHandler } from "../../helpers/axios";
import config from "../../../config";
import { objToUrlParams,generateRandomKey } from "../../helpers";
const Stripe = config.Stripe;


export function getSubscription(data) {
  return api()
    .get(`${config.routes.subscription}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function createCircle(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createCircle,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function listCircles(data) {
  return api()
    .get(`${config.routes.listCircles}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function listCategories(data) {
  return api()
    .get(`${config.routes.listCategories}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function getCircle(data) {
  return api()
    .get(`${config.routes.getCircle}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function circleDetails(data) {
  return api() 
    .get(`${config.routes.circleDetails}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateActiveCircle(data) {
  return api()
    .put(config.routes.updateActiveCircle, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateCircle(data) {
  return api()
    .put(config.routes.updateCircle, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function promoCode(data) {
  return api()
    .post(config.routes.promoCode, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function createNote(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createNote,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function deleteNote(data){
  return api()
    .delete(config.routes.deleteNote,{data: data})
    .then(res=>res.data)
    .catch(catchHandler);
}
export function listNotes(data) {
  return api()
    .get(`${config.routes.listNotes}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function getNote(data) {
  return api()
    .get(`${config.routes.getNote}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function updateNote(data) {
  return api()
    .put(`${config.routes.updateNote}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function createTask(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createTasks,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function listTasks(data) {
  return api()
    .get(`${config.routes.listTasks}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function deleteTask(data){
  return api()
    .delete(config.routes.deleteTask,{data: data})
    .then(res=>res.data)
    .catch(catchHandler);
}
export function getTask(data) {
  return api()
    .get(`${config.routes.getTask}?${objToUrlParams(data)}`)
    // .get(`${config.routes.getTask}?id=${data.id}&circle_id=${data.circle_id}`) 
    .then(res => res.data)
    .catch(catchHandler);
}
export function getMyTasks(data) {
  return api()
    .get(`${config.routes.listTasks}?${objToUrlParams({...data, filter_by: 'me'})}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getUnassignedTasks(data) {
  return api()
    .get(`${config.routes.listTasks}?${objToUrlParams({...data, filter_by: 'unassigned'})}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getOthersTasks(data) {
  return api()
    .get(`${config.routes.listTasks}?${objToUrlParams({...data, filter_by: 'others'})}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getCompletedTasks(data) {
  return api()
    .get(`${config.routes.listTasks}?${objToUrlParams({...data, filter_by: 'completed'})}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateTask(data) {
  return api()
    .put(`${config.routes.updateTask}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function createReminder(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createReminders,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function listReminders(data) {
  return api()
    .get(`${config.routes.listReminders}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function getReminderItems(data) {
  return api()
    .get(`${config.routes.getReminderItems}?${objToUrlParams(data)}`)
    // .get(`${config.routes.getTask}?id=${data.id}&circle_id=${data.circle_id}`) 
    .then(res => res.data)
    .catch(catchHandler);
}
export function updateReminder(data) {
  return api()
    .put(`${config.routes.updateReminder}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteReminder(data){
  return api()
    .delete(config.routes.deleteReminder,{data: data})
    .then(res=>res.data)
    .catch(catchHandler);
}
export function createRemindersItems(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createRemindersItems,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function updateReminderItem(data) {
  return api()
    .put(`${config.routes.updateReminderItem}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteReminderItem(data){
  return api()
    .delete(config.routes.deleteReminderItem,{data: data})
    .then(res=>res.data)
    .catch(catchHandler);
}
export function listReminderHistories(data) {
  return api()
    .get(`${config.routes.listReminderHistories}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function createCompletedReminderItem(data) {
  // data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createCompletedReminderItem,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function listMembers(data) {
  return api()
    .get(`${config.routes.listMembers}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function getMember(id) {
  return api()
    .get(`${config.routes.getCircleMember}?id=${id}}`)
    .then(res => res.data)
    .catch(catchHandler);
}

// Activities services starts here
export function createActivities(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createActivities,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function listActivities(data) {
  return api()
    .get(`${config.routes.listActivities}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function getActivities(id) {
  return api()
    .get(`${config.routes.getActivities}?id=${id}`)
    .then(res => res.data)
    .catch(catchHandler);
}

// Message services starts here
export function createMessages(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.messages,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function listMessages(data) {
  return api()
    .get(`${config.routes.messages}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function getMessages(id) {
  return api()
    .get(`${config.routes.getMessage}?id=${id}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function deleteMessage(payload) {
  return api()
    .delete(`${config.routes.deleteMessage}`, {params: payload, data: payload})
    .then(res => res.data)
    .catch(catchHandler)
}


// Comments services starts here
export function createComments(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.comments,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function listComments(data) {
  return api()
    .get(`${config.routes.comments}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function deleteComment(data) {
  data.csrf = generateRandomKey();
  return api()
    .delete(config.routes.comments,{data:data})
    .then(res => res.data)
    .catch(catchHandler);
}
export function editComment(data) {
  data.csrf = generateRandomKey();
  return api()
    .put(config.routes.comments,data)
    .then(res => res.data)
    .catch(catchHandler);
}

export function invite(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(`${config.routes.invite}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function listEvents(data) {
  return api()
    .get(`${config.routes.events}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
//list events with unavailability
export function listEventsWithUnavailability(payload) {
  // console.log("payload",payload)
    return api()
    .get(`${config.routes.allEvents}?circle_id=${payload.circle_id}&limit=10&offset=${payload.offset?payload.offset:0}&event_type=${payload.event_type}&sort_by=${payload.sort_by}&sort_order=${payload.sort_order}`)
    .then(res => res.data)
    .catch(catchHandler);
    
}

export function getEvents(data) {
  return api()
    .get(`${config.routes.event}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export function createEvent(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createEvent, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateEvent(data) {
  return api()
    .put(config.routes.updateEvent, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateAllEvents(data) {
  return api()
    .put(config.routes.updateAllEvents, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteEvent(data) {
  return api()
    .delete(config.routes.event, {data:data})
    .then(res => res.data)
    .catch(catchHandler)
}
export function draftEvent(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(`${config.routes.draftEvent}`, data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function checkDraftEvent(data) {
  return api()
    .get(`${config.routes.checkEventDraft}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateEventDraft(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.updateEventDraft, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function clearEventDraft(data) {
  return api()
    .delete(config.routes.clearEventDraft, {data: data})
    .then(res => res.data)
    .catch(catchHandler)
}
export function getDescription(data){
  data.csrf = generateRandomKey();
  return api()
    .post(`${config.routes.descriptions}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getContacts(data) {
  return api()
    .get(`${config.routes.listContacts}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getContact(data) {
  return api()
    .get(`${config.routes.getContact}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function addContact(data){
  data.csrf = generateRandomKey();
  return api()
    .post(`${config.routes.addContact}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function editContact(data){
  return api()
    .put(`${config.routes.editContact}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteContact(data) {
  return api()
    .delete(config.routes.deleteContact, {data: data})
    .then(res => res.data)
    .catch(catchHandler)
}
export function getCategories(data) {
  return api()
    .get(`${config.routes.getCategories}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function addCategory(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(`${config.routes.addCategory}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateCategory(payload) {
  return api() 
    .put(config.routes.editCategory, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteCategory(payload) {
  return api()
    .delete(config.routes.deleteCategory, {data: payload})
    .then(res => res.data)
    .catch(catchHandler)
}
export function getTaskList(data) {
  return api()
    .get(`${config.routes.getTaskList}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function createTaskList(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.addTaskList,data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateTaskList(data) {
  data.csrf = generateRandomKey();
  return api()
    .put(config.routes.addTaskList,data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function addPayment(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.payment, data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function freeAcessCode(data) {
  return api()
    .put(config.routes.getCircle, data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function checkAcessCode(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.redeemCodeCheck, data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function createToken(input){
  return new Promise((resolve, reject) => {
    if(!Stripe) reject('Cannot proceed')

    Stripe.createPaymentMethod('card',input, (status, response, error) => {
      if (response['error']) {
        reject(response['error'])
      }
      resolve(response)
    })
  });
}  
export function setUsersUnavailable(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.userUnavailabilty, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function editUserUnavailability(data) {
  return api()
    .put(config.routes.editUserUnavailability, data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getUserUnavailability(data) {
  return api() 
    .get(`${config.routes.userUnavailabilty}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getUnavailabilityById(data) {
  return api()
    .get(`${config.routes.getUnavailabilityById}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getUnavailabilityByUserid(data) {
  return api()
    .get(`${config.routes.getUserUnavailability}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteUserUnavailablity(data) {
  return api()
    .delete(`${config.routes.deleteUserUnavailablity}`, {data:data})
    .then(res => res.data)
    .catch(catchHandler)
}
export function listTodo(data) {
  return api()
    .get(`${config.routes.listTodo}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function exportData(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.exportData,data)
    .then(res => res.data)
    .catch(catchHandler);
}
export function deleteMedia(data) {
  return api()
    .delete(config.routes.deleteMedia, {data: data})
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteTaskList(data){
  return api()
    .delete(config.routes.getTaskList,{data: data})
    .then(res=>res.data)
    .catch(catchHandler);
}
export function deleteCircleCustomField(data){
  return api()
    .delete(config.routes.circleCustom,{data: data})
    .then(res=>res.data)
    .catch(catchHandler);
}
export function setCircleVisibility(data) {
  return api()
    .put(`${config.routes.circleVisibility}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}