import { api, catchHandler } from "../../helpers/axios";
import config from "../../../config";
import { objToUrlParams,generateRandomKey } from "../../helpers";


export function createRedeemCodes(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.partnerInvite,data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function serviceAdmin(data) {
  return api()
    .post(config.routes.serviceAdmin,data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function createServiceAdmin(data) {
  // data.csrf = generateRandomKey();
  return api()
    .post(config.routes.createServiceAdmin, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
// export function listRedeemCodes(data) {
//   return api()
//     .get(`${config.routes.redeemCodes}?${objToUrlParams(data)}`)
//     .then(res => res.data)  
//     .catch(catchHandler);
// }
export function listRedeemCodes(data) {
  return api()
    .get(`${config.routes.getRedeemList}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}

export function resendRedeemCodes(data) {
  data.csrf = generateRandomKey();
  return api()
    .post(config.routes.resendRedeemCode,data)
    .then(res => res.data)  
    .catch(catchHandler);
}

export function deleteRedeemCodes(data) {
  return api()
    .delete(config.routes.redeemCodeCheck,{data:data})
    .then(res => res.data)  
    .catch(catchHandler);
}

export function getAllServices(data) {
  return api()
    .get(`${config.routes.allServices}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function createServiceAndServiceAdmin(data){
  return api()
      .post(config.routes.createServiceAndAdmin,data)
      .then(res => res.data)
      .catch(catchHandler)
}
export function getServiceAndServiceAdmin(id){
  return api()
      .get(`${config.routes.createServiceAndAdmin}?${objToUrlParams({id})}`)
      .then(res => res.data)
      .catch(catchHandler)
}
export function deleteServiceAdmin(id){
  return api()
      .delete(config.routes.createServiceAndAdmin,{data:{id}})
      .then(res => res.data)
      .catch(catchHandler)
}
export function createJoinService(data){
  return api()
      .post(config.routes.joinService,data)
      .then(res => res.data)
      .catch(catchHandler)
}
export function deleteJoinService(data){
  return api()
      .delete(config.routes.joinService,{data:data})
      .then(res => res.data)
      .catch(catchHandler)
}
export function resendMagicLink(id){
  return api()
      .post(`${config.routes.createServiceAndAdmin}/resend-link`,{id})
      .then(res => res.data)
      .catch(catchHandler)
}
export function editServiceAndServiceAdmin(data) {
  return api()
    .put(config.routes.createServiceAndAdmin, data)
    .then(res => res.data)  
    .catch(catchHandler);
}
