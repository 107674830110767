import * as service from "./service";
import notification from '../../components/notification';
import {capitalize} from "../../helpers";
import { groupByDates } from '../../helpers'

export default {
    state: {
        activities:{}
    },
    reducers: {
        onRequest(state) {
            return {
                ...state,
                loading: true
            };
        },
        onError(state, { data = {} }) {
            notification("error", capitalize(data.message));
            return {
                ...state,
                loading: false
            };
        },
        onGetActivitiesSuccess(state, data) {
            return {
                ...state,
                loading: false,
                activities: data
            }
        },
    },
    effects: {
        async dashboardActivities(payload, rootState){
            this.onRequest()
            try{
                let res = await service.dashboardActivities(payload)
                let sortedResult = await groupByDates(res);
                this.onGetActivitiesSuccess(sortedResult)
                return res
            }catch(e) {
                this.onError(e)
            }
        },
    }
}