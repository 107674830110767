import { api, catchHandler } from "../../helpers/axios";
import config from "../../../config";
import { objToUrlParams } from "../../helpers";


export function createPromoCodes(data) {
  return api()
    .post(config.routes.promoCodes,data)
    .then(res => res.data)  
    .catch(catchHandler);
}

export function getPromoCodes(data) {
  return api()
    .get(`${config.routes.promoCodes}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function promoCodeDetails(data) {
  return api()
    .get(`${config.routes.promoCode}?${objToUrlParams(data)}`)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function editPromoCodes(data) {
  return api()
    .put(`${config.routes.promoCode}`, data)
    .then(res => res.data)
    .catch(catchHandler)
}