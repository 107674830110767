import React from 'react'
import { Modal, Form, Input } from 'antd'
import { generateLabels } from "../../helpers";
import { history } from '../../store';

const FormItem = Form.Item
const CreateFormWrapper = Form.create()(AcessCodeForm)

export default class AcessCodeModal extends React.Component {
  state = {
    visible: false
  }
  open() {
    this.showModal()
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
  showModal() {
    this.setState({visible: true})
  }
  cancelModal() {
    this.setState({visible: false})
    const form = this.form;
    form.resetFields();
    if(this.props.circle && this.props.circle.circle_slug)
      history.push(`/circle/${this.props.circle.circle_slug}/overview`)
  
  }
  handleSubmit() {
    const form = this.form
    form.validateFields((err, values)=> {
      if(err) return
      form.resetFields();
      this.setState({visible: false})
      values.join_code = values.join_code.toUpperCase()
      this.resolve(values)
    })
  }
  showPaymentModal(){
    let data = {status:true}
    this.setState({visible: false})
    this.resolve(data)
  }
  render() {
    return (
      <Modal
        className="simple-modal free-access-code-modal"
        title={generateLabels("ACCESS_CODE_TITLE")}
        visible={this.state.visible}
        okText="SAVE"
        width={416}
        onOk={this.handleSubmit.bind(this)} 
        onCancel={this.cancelModal.bind(this)} 
        cancelButtonProps={{className: 'cancel-btn'}}
      >
          <CreateFormWrapper ref = {ref => (this.form = ref)} self={this} />
      </Modal>
    )
  }
}

function AcessCodeForm(props) {
  const {getFieldDecorator} = props.form
  return (
         <Form layout="vertical"  className="simple-form"> 
              <FormItem label={generateLabels("ACCESS_CODE_EMAIL")}>
                {getFieldDecorator('user_email', {
                  rules: [{ required: true, message: 'Please input email' }],
                  initialValue: props.self && props.self.props &&  props.self.props.user && props.self.props.user.email ? props.self.props.user.email  : null
                })(
                  <Input  placeholder="Email" disabled/>
                )}
              </FormItem> 
              <FormItem label={generateLabels("ACCESS_CODE_CODE")}>
                {getFieldDecorator('join_code', {
                  rules: [{ required: true, message: generateLabels("ACCESS_CODE_CODE_BLANK_ERROR") }],//,{min:6,message:'Invalid free access code'},{max:6,message:'Invalid free access code'}],
                  validateTrigger:'onBlur'
                })(
                  <Input placeholder="eg: FRE123"/>
                )}
              </FormItem> 
          </Form>
  )
}


