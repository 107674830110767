import * as service from "./service";
import { history } from "../../store";
import notification from '../../components/notification';
import { setCircleCookie,getCircleCookie } from "../../helpers/utility";
import { capitalize, generateLabels, groupMessagesByDates } from "../../helpers";
import { Modal } from 'antd';
import lodash from 'lodash'
import moment from 'moment';

export default {
  state: {
    circles:{},
    circle:getCircleCookie() || {},
    notes:{},
    note:{},
    tasks:{},
    task:{},
    reminder:{},
    reminders:{},
    reminderItems:{},
    reminderHistory:{},
    members:{},
    member:{},
    messages:{},
    message:{},
    comments:{},
    comment:{},
    events:[],
    event:{},
    taskList:{},
    eventsPerDay:{},
    tempEvents:[], 
    groupedEvents: [], 
    eventCount: 0, 
    dummyEvents: [],
    menuToggler: false,
    categoryList: [],
    categoryId: [],
    eventDraft: null,
    isDetailsToListPage: false,
    listingPageLimit: 10,
    showHiddenCircle: false,
    circleVisibility: {},
    manageCirclePopupLoading: false

  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
      };
    },
    onSuccess(state) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state,data) {
      let err = data && data.message ? data.message : data && data.data && data.data.message ? data.data.message :  'Please try again later'  ;
      notification("error", capitalize(err));
      return {
        ...state,
        loading: false
      };
    },
    onTaskListError(state,data) {
      let err = data && data.message ? data.message : data && data.data && data.data.message ? data.data.message :  'Please try again later'  ;
      Modal.error({ title: capitalize(err) });
      return {
        ...state,
        loading: false
      };
    },
    onMenuToggle(state) {
      return {
        ...state, 
        menuToggler: !state.menuToggler
      }
    },
    onNoMenu(state) {
      return {
        ...state, 
        menuToggler: false
      }
    },
    chosenDate(state, data) {
      return {
        ...state, 
        choosenDate: data
      }
    },
    onResetState(state,data){
      return {
        ...state,
        circles:{},
        circle: {},
        notes:{},
        note:{},
        tasks:{},
        task:{},
        reminder:{},
        reminders:{},
        reminderItems:{},
        reminderHistory:{},
        members:{},
        member:{},
        messages:{},
        message:{},
        comments:{},
        comment:{},
        events:[],
        event:{},
        taskList:{},
        tempEvents:[], 
        groupedEvents: [], 
        eventCount: 0, 
        dummyEvents:[],
        subscription:{},
      }
    },
    onResetTempState(state,data){
      return {
        ...state,
        tempEvents:[], groupedEvents: [], eventCount: 0, dummyEvents: []
      }
    },
    onGetSubscriptionSuccess(state, data) {
      return {
        ...state,
        loading: false,
        subscription:data
      };
    },
    onHideCircle(state, data) {
      return {
        ...state,
        showHiddenCircle: false
      };
    },
    onShowCircle(state) {
          return {
            ...state,
            showHiddenCircle: true, 
          };
    },
    clearCircle(state) {
      return {
        ...state,
        circle: {}
      };
    },

    onListCategoriesSuccess(state, data) {
      let categoryId
      if(data && data.rows && data.rows.length){
        categoryId = data.rows.map(a => a.id);
      }
      let categoryList = lodash.chain(data.rows)
      .groupBy('circle_id')
      .toPairs()
      .map(function (currentItem) { return lodash.zipObject(['date', 'data'], currentItem) }).value();
      return {
        ...state,
        loading: false,
        categoryList,
        categoryId: categoryId ? categoryId : []
      };
    },

    onCreateCircleSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_CREATE_CIRCLE"));
      return {
        ...state,
        loading: false,
        circle:data
      };
    },
    onListCirclesSuccess(state, data) {
      let circlesId = []
      let circle = state.circle
      if(data && data.rows && data.rows.length){
        circlesId = data.rows.map(a => a.id);
        if(data.rows.length === 1){
          setCircleCookie(data.rows[0])
          circle = data.rows[0]
        }
      }
      else {
        circle = {}
      }
      return {
        ...state,
        loading: false,
        circles:data,
        circlesId: circlesId,
        circle: circle,
        manageCirclePopupLoading: false
      };
    },
    onGetCircleSuccess(state, data) {
      setCircleCookie(data)
      return {
        ...state,
        loading: false,
        circle:data,
        manageCirclePopupLoading: false

      };
    },
    onGetCircleDetailsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        circleDetails: data
      }
    },
    onUpdateActiveCircleSuccess(state, data) {
      // notification("success", generateLabels("TOASTERS_CIRCLE_UPDATE"))
      return {
        ...state,
        loading: false,
        activeCircle: data
      }
    },
    onUpdateCircleSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_CIRCLE_UPDATE"))
      return {
        ...state,
        loading: false,
        circle: data
      }
    },
    onUseFreeAccessCodeSuccess(state, data) {
      notification('success', generateLabels("ACCESS_CODE_SUCCESS"))
      return {
        ...state,
        loading: false,
        circle: data
      }
    },
    onPromoCodeSuccess(state, data) {
      notification('success', generateLabels("ACCESS_CODE_SUCCESS"))
      return {
        ...state,
        loading: false,
        promocode: data,
        circle:{...state.circle,subscription_type : 'code',status:'active'}
      }
    },
    onListNotesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        notes:data,
        note:{}
      };
    },
    onGetNoteSuccess(state, data) {
      return {
        ...state,
        loading: false,
        note:data
      };
    },
    onUpdateNoteSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_NOTE_UPDATE"));
      return {
        ...state,
        loading: false,
        note:data
      };
    },
    onCreateNoteSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_NOTE_CREATE"));
      return {
        ...state,
        loading: false,
        note:data
      };
    },
    onDeleteNoteSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_NOTE_DELETE"));
      return {
        ...state,
        loading: false,
        deleted:data
      };
    },
    onCreateTaskSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_TASK_CREATE"));
      return {
        ...state,
        loading: false,
        task:data
      };
    },
    onListTasksSuccess(state, data) {
      return {
        ...state,
        loading: false,
        tasks:data,
        task:{}
      };
    },
    onGetTaskSuccess(state, data) {
      return {
        ...state,
        loading: false,
        task:data
      };
    },
    onGetMyTasksSuccess(state, data) {
      return {
        ...state, 
        loading: false,
        myTasks: data
      }
    },
    onGetUnassignedTasksSuccess(state, data) {
      return {
        ...state,
        loading: false,
        unassignedTasks: data
      }
    },
    onGetOthersTasksSuccess(state, data) {
      return {
        ...state,
        loading: false,
        othersTasks: data
      }
    },
    onGetCompletedTasksSuccess(state, data) {
      return {
        ...state,
        loading: false,
        completedTasks: data
      }
    },
    onUpdateTaskSuccess(state, data){
      notification('success', generateLabels("TOASTERS_TASK_UPDATE"))
      return {
        ...state,
        loading: false,
        // task: data
      }
    },
    onListRemindersSuccess(state, data) {
      return {
        ...state,
        loading: false,
        reminders:data,
        reminderItems: {}
      };
    },
    onGetReminderItemsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        reminderItems:data,
        reminderHistory: {}
      };
    },
    onCreateReminderSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_REMINDER_CREATED"));
      return {
        ...state,
        loading: false,
        reminder:data
      };
    },
    onUpdateReminderSuccess(state, data){
      notification('success', generateLabels("TOASTERS_REMINDER_UPDATED"))
      return {
        ...state,
        loading: false,
        reminder: data
      }
    },
    onDeleteReminderSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_REMINDER_DELETED"))
      return {
        ...state, 
        loading: false,
        deleted: data
      }
    },
    onCreateReminderItemSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_REMINDER_ITEM_CREATED"));
      return {
        ...state,
        loading: false,
        reminderlist:data
      };
    },
    onUpdateReminderItemSuccess(state, data){
      notification('success', generateLabels("TOASTERS_REMINDER_ITEM_UPDATED"))
      return {
        ...state,
        loading: false,
        reminderlist: data
      }
    },
    onCreateCompletedReminderItemSuccess(state, data, message) {
      notification("success", generateLabels(message));
      return {
        ...state,
        loading: false,
        reminderHistory:data
      };
    },
    onGetMembersSuccess(state, data) {
      return {
        ...state,
        loading: false,
        members:data
      };
    },
    onGetMemberSuccess(state, data) {
      return {
        ...state,
        loading: false,
        member:data
      };
    },
    onCreateMessagesSuccess(state, data) {
     // notification("success", 'Messages Created');
      return {
        ...state,
        loading: false,
        message:data
      };
    },
    onListMessagesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        messages:data
      };
    },
    onGetMessagesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        message:data
      };
    },
    onDeleteMessageSuccess(state, data) {
      notification('success', "Message deleted")
      return {
        ...state,
        loading: false,
      }
    },
    onCreateCommentsSuccess(state, data) {
     // notification("success", 'Comments Added');
      return {
        ...state,
        loading: false,
        comment:data
      };
    },
    onDeleteCommentSuccess(state, data) {
     notification("success", 'Comment deleted');
      return {
        ...state,
        loading: false
      };
    },
    onEditCommentSuccess(state, data) {
      notification("success", 'Comment edited successfully');
       return {
         ...state,
         loading: false
       };
     },
    onListCommentsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        comments:data
      };
    },
    onInviteEmailsSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_INVI_SENT"));
      return {
        ...state,
        loading: false,
        invited: data
      }
    },
    onSelectCalendarDates(state, data) {
      return {
        ...state, 
        selectedCalendarDates: data
      }
    },
    onListEventsSuccess(state, data) {
      return { 
        ...state,
        loading: false,
        events:data
      };
    },
    onListDayEventsSuccess(state, data) {
      return { 
        ...state,
        loading: false,
        eventsPerDay:data
      };
    },
    onGetEventsSuccess(state, data) {
      return { 
        ...state,
        loading: false,
        event:data
      };
    },
    onCreateEventSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_EVT_CREATE"))
      history.push(`/circle/${data.circle_slug}/calendar`)
      return {
        ...state,
        loading: false,
        event: data
      }
    },
    onUpdateEventSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_EVT_UPDATE"))
      history.push(`/circle/${data.circle_slug}/event/${data.res.id}`)
      return {
        ...state, 
        loading: false,
        event: data
      }
    },
    onUpdateAllEventSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_EVT_UPDATE"))
      history.push(`/circle/${data.circle_slug}/calendar`)
      return {
        ...state, 
        loading: false,
        event: data
      }
    },
    onDeleteEventSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_EVT_DELETE"))
      return {
        ...state, 
        loading: false,
      }
    },
    onClearEvent(state, data) {
      return {
        ...state,
        event: {}
      }
    },
    onDraftEventSuccess(state, data) {
      return {
        ...state,
        loading: false,
        eventDraft: data
      }
    },
    onCheckEventDraftSuccess(state, data) {
      return  {
        ...state,
        loading: false,
        eventDraft: data
      }
    },
    onClearEventDraftSuccess(state) {
      return {
        ...state,
        eventDraft: null
      }
    },
    onUpdateEventDraft(state, data) {
      notification("success", generateLabels("TOASTERS_EVT_CREATE"))
      history.push(`/circle/${data.circle_slug}/event/${data.id}`)
      return {
        ...state,
        eventDraft: null
      }
    },
    onGetDescriptionSuccess(state, data) {
      return {
        ...state,
        loading: false,
        description: data
      }
    },
    onGetContactsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        contacts: data,
        editingContact:{}
      }
    },
    onGetContactSuccess(state, data){
      return {
        ...state, 
        loading: false,
        editingContact: data
      }
    },
    onAddContactSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_CONTACT_ADD"))
      return {
        ...state,
        loading: false,
        contact: data
      }
    },
    onEditContactSuccess(state, data) {
      notification('success', generateLabels("CONTACT_UPDATED"))
      return {
        ...state, 
        loading: false,
        contact: data,
        editingContact: null
      }
    },
    onDeleteContactSuccess(state, data) {
      notification('success', generateLabels("CONTACT_DELETED"))
      return {
        ...state, 
        loading: false,
        deleted: data
      }
    },
    onGetCategoriesSuccess(state, data){
      return {
        ...state,
        loading: false,
        categories: data
      }
    },
    onAddCategorySuccess(state, data) {
      return {
        ...state,
        loading: false, 
        addedCategory: data
      }
    },
    onUpdateCategorySuccess(state, data) {
      notification("success", generateLabels("TOASTERS_CATEGORY_UPDATE"))
      return {
        ...state,
        loading: false,
      }
    },
    onDeleteCategorySuccess(state, data) {
      notification("success", generateLabels("TOASTERS_CATEGORY_DELETE"))
      return {
        ...state,
        loading: false,
      }
    },
    onListActivitiesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        activities: data
      }
    },
    onGetTaskListSuccess(state, data) {
      return {
        ...state,
        loading: false,
        taskList: data
      }
    },
    onDeleteTaskSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_TASK_DELETE"))
      return {
        ...state, 
        loading: false,
        deleted: data
      }
    },
    onCreateTaskListSuccess(state, data) {
      return {
        ...state,
        loading: false,
        addedTaskList: data
      }
    },
    onUpdateTaskListSuccess(state, data) {
      notification('success', "Task list Updated")
      return {
        ...state,
        loading: false,
      }
    },
    onGetReminderListSuccess(state, data) {
      return {
        ...state,
        loading: false,
        reminderList: data
      }
    },
    onDeleteReminderItemSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_REMINDER_ITEM_DELETED"))
      return {
        ...state, 
        loading: false,
        deleted: data
      }
    },
    onGetReminderHistorySuccess(state, data) {
      return {
        ...state,
        loading: false,
        reminderHistory: data
      }
    },
    onPaymentSuccess(state, { data }) {
      notification("success", generateLabels("TOASTERS_PAY_COMPLETED"));
      return {
        ...state,
        loading: false,
      };
    },
    onAcessCodeCheckSuccess(state, { data }) {
      return {
        ...state,
        loading: false,
      };
    },
    onSetUserUnavailableSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_UNAVAILABLE"))
      return {
        ...state,
        loading: false
      }
    },
    onEditUserUnavailabilitySuccess(state, data) {
      notification('success', generateLabels("TOASTERS_UNAVAILABLE_UPDATE"))
      return {
        ...state,
        loading: false
      }
    },
    onGetUserUnavailabilitySuccess(state, data) {
      return {
        ...state, 
        loading: false,
        unavailability: data
      }
    },
    onGetUnavailabilityByIdSuccess(state, data) {
      return {
        ...state, 
        loading: false,
        unavailabilityById: data
      }
    },
    onGetUnavailabilityByUseridSuccess(state, data) {
      return {
        ...state,
        loading: false,
        unavailabilityByUserId: data
      }
    },
    onDeleteUserUnavailabilitySuccess(state, data) {
      notification('success', generateLabels("TOASTERS_UNAVAILABLE_DELETE"))
      return {
        ...state,
        loading: false,
      }
    },
    
    onListEventsWithUnavailabilitySuccess(state, data) {
      let event = data && data.rows && data.rows.length > 0 ? data.rows : []
      let newEvent = state.tempEvents || []
      if (event.length > 0)
        newEvent = newEvent.concat(event)
      
      let dummyEvents = data && data.dummyData && data.dummyData.length > 0 ? data.dummyData : [];
      let newData = state.dummyEvents || []
      if (event.length > 0)
        newData = newData.concat(dummyEvents)

      let eventList = lodash.chain(newData)
      .groupBy('start_date')
      .toPairs()
      .map(function (currentItem) { return lodash.zipObject(['date', 'data'], currentItem) }).value();

      let sortedResult 	= lodash.sortBy(eventList, function(item) {
        return new Date( item.date );
      });
      return {
        ...state,
        loading: false,
        tempEvents: newEvent.length > 0 ? newEvent : [],
        dummyEvents: newData.length > 0 ? newData : [],
        groupedEvents: sortedResult.length > 0  ? sortedResult : [],
        eventCount: data && data.count ? data.count : 0
      }
    },
    
    onSetPaginationCurrent(state, data) {
      return {
        ...state, 
        loading: false,
        current: data
      }
    },
    onListTodoSuccess(state, data) {
      return {
        ...state,
        loading: false,
        todo: data
      }
    },
    onExportDataSuccess(state, data) {
      notification('success', capitalize(data.message))
      return {
        ...state,
        loading: false,
      }
    },
    onDeleteCircleCustomFieldSuccess(state, data) {
      notification('success', "Removed Custom field")
      return {
        ...state,
        loading: false,
      }
    },
    onDeleteTaskListSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_TASK_REMOVE"))
      return {
        ...state,
        loading: false,
      }
    },
    setCalenderViewDate(state,data){
      return {
        ...state,
        calenderViewDate:data
      }
    },
    OnDetailsPageToListBack(state,data){
      return{
        ...state,
        isDetailsToListPage: data
      }
    },
    setListingPageLimit(state,data){
      return{
        ...state,
        listingPageLimit: data
      }
    },
    onSetCircleVisibility(state,data){
      return{
        ...state,
        circleVisibility: data,
        // manageCirclePopupLoading: false
      }
    },
    onManageCirclePopupLoading(state) {
      return {
        ...state,
        manageCirclePopupLoading: true,
      };
    }
  
  },
  effects: {
    async reset(payload, rootState) {
      try {
        this.onResetState();
      } catch (e) {
        this.onError(e);
      }
    },
    async tempRemoval() {
      try {
        this.onResetTempState();
      } catch (e) {
        this.onError(e);
      }
    },
    async showCircles(payload, rootState) {
      try {
        this.onShowCircle();
      } catch (e) {
        this.onError(e);
      }
    },
    async hideCircles(payload, rootState) {
      try {
        this.onHideCircle();
      } catch (e) {
        this.onError(e);
      }
    },
    async menuToggle() {
      this.onMenuToggle()
    },
    async getSubscription(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getSubscription(payload);
        this.onGetSubscriptionSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async createCircle(payload, rootState) {
       this.onRequest();
       try {
         let res = await service.createCircle(payload);
         this.onCreateCircleSuccess(res);
         return res;
       } catch (e) {
         this.onError(e);
       }
    },
    async listCircles(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listCircles(payload);
        this.onListCirclesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async listCategories(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listCategories(payload);
        this.onListCategoriesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async updateActiveCircle(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.updateActiveCircle(payload)
        this.onUpdateActiveCircleSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getCircle(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getCircle(payload);
        if(res.id) {
          this.updateActiveCircle({last_active_circle: res.id})
        }
        this.onGetCircleSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getCircleDetails(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.circleDetails(payload)
        this.onGetCircleDetailsSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async updateCircle(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.updateCircle(payload)
        this.onUpdateCircleSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async useFreeAccessCode(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.updateCircle(payload)
        this.onUseFreeAccessCodeSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async promoCode(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.promoCode(payload)
        this.onPromoCodeSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async createNote(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createNote(payload);
        this.onCreateNoteSuccess(res);
        // this.listNotes({circle_id:payload.circle_id,limit:10})
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async deleteNote(payload,rootState){
      this.onRequest();
      try{
        let res=await service.deleteNote(payload);
        this.onDeleteNoteSuccess(res);
        this.listNotes({circle_id:payload.circle_id,limit:10})
        return res;
      } catch(e){
        this.onError(e);
      }
    },
    async listNotes(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listNotes(payload);
        this.onListNotesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getNote(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getNote(payload);
        this.onGetNoteSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async updateNote(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.updateNote(payload)
        this.onUpdateNoteSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async createTask(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createTask(payload);
        this.listTasks({circle_id:payload.circle_id,limit:10})
        this.onCreateTaskSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async listTasks(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listTasks(payload);
        this.onListTasksSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async deleteTask(payload,rootState){
      this.onRequest();
      try{
        let res=await service.deleteTask(payload);
        this.onDeleteTaskSuccess(res);
        //this.listTasks({circle_id:payload.circle_id,limit:10})
        return res;
      } catch(e){
        this.onError(e);
      }
    },
    async getTask(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getTask(payload);
        this.onGetTaskSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getMyTasks(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.getMyTasks(payload)
        this.onGetMyTasksSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      } 
    },
    async getUnassignedTasks(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.getUnassignedTasks(payload)
        this.onGetUnassignedTasksSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      } 
    },
    async getOthersTasks(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.getOthersTasks(payload)
        this.onGetOthersTasksSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      } 
    },
    async getCompletedTasks(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.getCompletedTasks(payload)
        this.onGetCompletedTasksSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      } 
    },
    async updateTask(payload, rootState){
      this.onRequest()
      try{
        let res = await service.updateTask(payload)
        this.onUpdateTaskSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async createReminder(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createReminder(payload);
        this.onCreateReminderSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async listReminders(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listReminders(payload);
        this.onListRemindersSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getReminderItems(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getReminderItems(payload);
        this.onGetReminderItemsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async updateReminder(payload, rootState){
      this.onRequest()
      try{
        let res = await service.updateReminder(payload)
        this.onUpdateReminderSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async deleteReminder(payload,rootState){
      this.onRequest();
      try{
        let res=await service.deleteReminder(payload);
        this.onDeleteReminderSuccess(res);
        //this.listTasks({circle_id:payload.circle_id,limit:10})
        return res;
      } catch(e){
        this.onError(e);
      }
    },
    async createRemindersItems(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createRemindersItems(payload);
        this.onCreateReminderItemSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async updateReminderItem(payload, rootState){
      this.onRequest()
      try{
        let res = await service.updateReminderItem(payload)
        this.onUpdateReminderItemSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async deleteReminderItem(payload,rootState){
      this.onRequest();
      try{
        let res=await service.deleteReminderItem(payload);
        this.onDeleteReminderItemSuccess(res);
        //this.listTasks({circle_id:payload.circle_id,limit:10})
        return res;
      } catch(e){
        this.onError(e);
      }
    },
    async listReminderHistories(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listReminderHistories(payload);
        this.onGetReminderHistorySuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async createCompletedReminderItem(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createCompletedReminderItem(payload);
        this.getReminderItems({circle_id:payload.circle_id, reminder_id:payload.reminder_id, nopaging: 1})
        console.log("await res= ", payload)
        if(payload.completed){
          this.onCreateCompletedReminderItemSuccess(res, 'TOASTERS_REMINDER_ITEM_COMPLETED');
        }else{
          this.onCreateCompletedReminderItemSuccess(res, 'TOASTERS_REMINDER_ITEM_OPENED');
        }
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async listMembers(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listMembers(payload);
        this.onGetMembersSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getMember(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getMember(payload);
        this.onGetMemberSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async createActivities(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createActivities(payload);
        this.onCreateActivitiesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async listActivities(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listActivities(payload);
        this.onListActivitiesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getActivities(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getActivities(payload);
        this.onGetActivitiesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },

    async createMessages(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createMessages(payload);
        this.listMessages({circle_id:payload.circle_id,limit:10})
        this.onCreateMessagesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async listMessages(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listMessages(payload);
        let sortedResult = await groupMessagesByDates(res);
        this.onListMessagesSuccess(sortedResult);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getMessages(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getMessages(payload);
        this.onGetMessagesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async deleteMessage(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.deleteMessage(payload)
        this.onDeleteMessageSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },

    async createComments(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createComments(payload);
        // delete payload.comments
        this.listComments({...payload, nopaging: 1})
        this.onCreateCommentsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },

    async deleteComment(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.deleteComment(payload);
        // delete payload.comments
        this.listComments(payload)
        this.onDeleteCommentSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async editComment(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.editComment(payload);
        // delete payload.comments
        this.listComments(payload)
        this.onEditCommentSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async listComments(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listComments(payload);
        this.onListCommentsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async inviteEmails(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.invite(payload)
        this.onInviteEmailsSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
      }
    },
    async selectCalendarDates(payload, rootState) {
      this.onSelectCalendarDates(payload)
    },
    async listEvents(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listEvents(payload);
        this.onListEventsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async listDayEvents(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listEvents(payload);
        this.onListDayEventsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async listEventsWithUnavailability(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listEventsWithUnavailability(payload);
        let dummyRes = await this.groupByDates(res)
        await this.onListEventsWithUnavailabilitySuccess(dummyRes);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async groupByDates(payload) {
      try {
        let dummyData = await this.addMissingDates(payload.rows)
        let allData = { dummyData, rows: payload.rows, count: payload.count }
        return allData
      } catch (e) {
        this.onError(e);
      }
    },
    async addMissingDates(payload) {
      try {
        let new_array = [];
        for (let i = 0; i < payload.length; i++) {
          let event = payload[i]
          let startDate = moment(event.start_date);
          let endDate = moment(event.end_date);
          let diff = endDate.diff(startDate, 'days');
          
          new_array.push(event);
          if (diff > 0) {
            for (let i = 0; i < diff; i++) {
              startDate.add(1, 'days');
              let dummyObj = {};
              dummyObj = Object.assign({}, event);
              dummyObj.start_date = startDate.format('YYYY-MM-DD')
              new_array.push(dummyObj);
              dummyObj = {};
            }
          }
          // else new_array.push(event);
        }
        return new_array;
      }
      catch (e) {
        console.log("Error ============>", e)
      }
    },






    async getEvents(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getEvents(payload);
        this.onGetEventsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e
      }
    },
    async createEvent(payload, rootState){
      this.onRequest()
      try{
        let res = await service.createEvent(payload)
        let reciepients = payload.reciepients ? payload.reciepients.map(item => {return {email: item}}) : null
        if(reciepients) {
          service.invite({
            circle_id : res.circle_id,
            event_id: res.id,
            invitation_type: 'event',
            reciepients: reciepients
          })
        }
        this.onCreateEventSuccess({...res, circle_slug: payload.circle_slug, id: payload.id})
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async updateEvent(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.updateEvent(payload)
        let reciepients = payload.reciepients ? payload.reciepients.map(item => {return {email: item}}) : null
        if(reciepients) {
          await service.invite({
            circle_id : res.circle_id,
            event_id: res.id,
            invitation_type: 'event',
            reciepients: reciepients
          })
        }
        this.onUpdateEventSuccess({res, circle_slug: payload.circle_slug})
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async updateAllEvents(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.updateAllEvents(payload)
        let reciepients = payload.reciepients ? payload.reciepients.map(item => {return {email: item}}) : null
        if(reciepients) {
          await service.invite({
            circle_id : res.circle_id,
            event_id: res.id,
            invitation_type: 'event',
            reciepients: reciepients
          })
        }
        this.onUpdateAllEventSuccess({res, circle_slug: payload.circle_slug})
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async deleteEvent(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.deleteEvent(payload)
        this.onDeleteEventSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async clearEvent(payload, rootState) {
      try {
        this.onClearEvent()
      }
      catch(e) {
        this.onError(e)
      }
    },
    async draftEvent(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.draftEvent(payload)
        this.onDraftEventSuccess(res)
        return (res)
      }
      catch(e) {
        this.onError(e)
      }
    },
    async checkEventDraft(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.checkDraftEvent(payload)
        this.onCheckEventDraftSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async clearEventDraft(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.clearEventDraft(payload)
        this.onClearEventDraftSuccess()
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async updateEventDraft(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.updateEventDraft(payload)
        this.onUpdateEventDraft({...res, circle_slug: payload.circle_slug})
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getDescription(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getDescription(payload)
        this.onGetDescriptionSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getContacts(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.getContacts(payload)
        this.onGetContactsSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getContact(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.getContact(payload)
        this.onGetContactSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async addContact(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.addContact(payload)
        this.onAddContactSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async editContact(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.editContact(payload)
        this.onEditContactSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async deleteContact(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.deleteContact(payload)
        this.onDeleteContactSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getCategories(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getCategories(payload)
        this.onGetCategoriesSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async addCategory(payload, rootState) {
      this.onRequest()
      try{
        let res = await service.addCategory(payload)
        this.onAddCategorySuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async updateCategory(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.updateCategory(payload)
        this.onUpdateCategorySuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async deleteCategory(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.deleteCategory(payload)
        this.onDeleteCategorySuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getTaskList(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getTaskList(payload)
        this.onGetTaskListSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async createTaskList(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.createTaskList(payload)
        this.onCreateTaskListSuccess(res)
        return res
      }
      catch(e) {
        this.onTaskListError(e)
      }
    },
    async updateTaskList(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.updateTaskList(payload)
        this.onUpdateTaskListSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async addPayment(payload, rootState) {
      this.onRequest();
      try {
        let stripeData = {...payload}
            delete stripeData.circle_id
            delete stripeData.subscription_id
        const token = await service.createToken(stripeData);
        payload.stripeToken = token.id;
        let res = await service.addPayment(payload);
        this.onPaymentSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async checkAcessCode(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.checkAcessCode(payload);
        this.onAcessCodeCheckSuccess(res);
        this.freeAcessCode(payload)
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async freeAcessCode(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.freeAcessCode(payload);
        this.onPaymentSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async setUserUnavailable(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.setUsersUnavailable(payload)
        this.onSetUserUnavailableSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getUserUnavailability(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getUserUnavailability(payload)
        this.onGetUserUnavailabilitySuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getUnavailabilityById(payload, rootState) {
      try {
        let res = await service.getUnavailabilityById(payload)
        this.onGetUnavailabilityByIdSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getUnavailabilityByUserid(payload, rootState) {
      try {
        let res = await service.getUnavailabilityByUserid(payload)
        this.onGetUnavailabilityByUseridSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async editUserUnavailability(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.editUserUnavailability(payload)
        this.onEditUserUnavailabilitySuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async deleteUserUnavailablity(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.deleteUserUnavailablity(payload)
        this.onDeleteUserUnavailabilitySuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async setPaginationCurrent(current, rootState) {
      this.onRequest() 
      try {
        this.onSetPaginationCurrent(current)
      }
      catch(e) {
        this.onError(e)
      }
    },
    async listTodo(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.listTodo(payload)
        this.onListTodoSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async exportData(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.exportData(payload)
        this.onExportDataSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async deleteTaskList(payload,rootState){
      this.onRequest();
      try{
        let res=await service.deleteTaskList(payload);
        this.onDeleteTaskListSuccess(res);
        return res;
      } catch(e){
        this.onError(e);
      }
    },
    async deleteCircleCustomField(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.deleteCircleCustomField(payload)
        this.onDeleteCircleCustomFieldSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async setCalenderViewPage(data){
      try{
        this.setCalenderViewDate(data)
      }catch(e){}
    },
    async DetailsPageToListBackUpdate(payload){ 
      try{
        this.OnDetailsPageToListBack(payload)
      }catch(e){
        this.onError(e)
      }
    },
    async ListingPageLimitUpdate(payload){ 
      try{
        this.setListingPageLimit(payload)
      }catch(e){
        this.onError(e)
      }
    },
    async setCircleVisibility(payload, rootState) {
      this.onManageCirclePopupLoading()
      try {
        let res = await service.setCircleVisibility(payload)
        await this.onSetCircleVisibility(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
  }
};
